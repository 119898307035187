import React from 'react'
import {connect} from 'react-redux'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    NavLink,
    Row,
    FormFeedback, FormGroup, Alert
} from 'reactstrap'


import * as userAction from '../../../redux/actions/userAction'
import * as sessionAction from '../../../redux/actions/sessionAction'
import apiService from '../../../shared/services/api.service'
import AlertMessages from '../../../shared/components/AlertMessages'
import UserEntity from '../../Management/models/UserEntity'
import {NotificationContainer} from "react-notifications";
import {ToastContainer} from "react-toastify";
import LoadingPanel from "../../../shared/components/LoadingPanel";
//import logo_background from '../../../assets/img/brand/logo_background.png'
//import logo from '../../../assets/img/brand/logo_login.png'
//import logo from '../../../../public/assets/img/brand/firma_logo.png'


class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            userData: new UserEntity(),
            forgetPasswordData: new UserEntity(),
            activeForm: 'LOGIN',
            loading: false,
            alertShow: false,
            alertClose: false,
            alertShowForgetPassword: false,
            alertCloseForgetPassword: false,
            alertType: '',
            alertMessage: '',
            validForgetPasswordUserName: false,
            validForgetPasswordEmailAddress: false,

        }

        this.alertShow = this.alertShow.bind(this)
        this.alertClose = this.alertClose.bind(this)
        this.alertShowForgetPassword= this.alertShowForgetPassword.bind(this)
        this.alertCloseForgetPassword = this.alertCloseForgetPassword.bind(this)
        this.login = this.login.bind(this)
        this.passwordReset=this.passwordReset.bind(this)
        this.openForgetPasswordForm = this.openForgetPasswordForm.bind(this)
        this.closeForgetPasswordForm = this.closeForgetPasswordForm.bind(this)
        this.validForgetPasswordControl=this.validForgetPasswordControl.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)

    }


    componentDidMount(args) {


    }

    validForgetPasswordControl() {
        let retValue = false

        this.setState({
            validForgetPasswordUserName: false,
            validForgetPasswordEmailAddress: false,

        })


        if (this.state.forgetPasswordData.username === '') {
            this.setState({validForgetPasswordUserName: true})
            retValue = true
        }

        if (this.state.forgetPasswordData.email_address === '') {
            this.setState({validForgetPasswordEmailAddress: true})
            retValue = true
        }


        return retValue
    }


    alertShow(type, message) {
        this.setState({alertShow: true, alertType: type, alertMessage: message})
    }

    alertClose() {
        this.setState({alertShow: false})
    }

    alertShowForgetPassword(type, message) {
        this.setState({alertShowForgetPassword: true, alertType: type, alertMessage: message})
    }

    alertCloseForgetPassword() {
        this.setState({alertShowForgetPassword: false})
    }

    handleInputChange = (field, e) => {
        let userData = this.state.userData
        userData[field] = e.target.value
        userData['userId'] = '1'
        this.setState({userData})
    }

    handleForgetPasswordInputChange = (field, e) => {
        let userData = this.state.forgetPasswordData
        userData[field] = e.target.value
        this.setState({userData})
    }


    handleKeyPress = (event) => {
        if (event.key == 'Enter') {
            this.login()
        }
    }

    openForgetPasswordForm() {


        this.setState({activeForm:'FORGET_PASSWORD',loading: false})

    }

    closeForgetPasswordForm() {

       // this.state.activeForm = 'LOGIN'
        this.setState({activeForm:'LOGIN',loading: false})

    }

    login() {


        let sessionData = {}
        let userName = this.state.userData.username
        let password = this.state.userData.password


        if ((userName === undefined)
            || (userName.trim() === '')) {
            return
        }


        this.setState({loading: true})
        apiService.onRequest('POST', 'api/user/login', this.state.userData)
            .then(res => {


                this.setState({
                    userData: res.data,
                    loading: false
                })

                sessionData = {
                    'user_id': this.state.userData.id,
                    'user_role_type': this.state.userData.role_type

                }


                userAction.setUserData(this.state.userData)
                sessionAction.setSessionData(sessionData)

                console.log(this.props)
                this.props.history.push({
                    pathname: '/dashboard'

                })


            })
            .catch(error => {
                this.setState({loading: false})
                if (error.response === undefined) {
                    this.alertShow('error', 'Hatalı kullanıcı veya şifre')
                    return
                }
                if ((error.response.status !== 200) && (error.response.status !== 409)) {
                    this.alertShow('error', 'Hatalı kullanıcı veya şifre')
                } else {
                    this.alertShow('error', 'Hatalı kullanıcı veya şifre')
                }
            })


    }

    passwordReset() {


        if (this.validForgetPasswordControl() === true) {
            return
        }

        this.setState({loading: true})
        apiService.onRequest('POST', 'api/user/forgetPassword', this.state.userData,this.props.customStore.sessionData)
            .then(res => {


                this.setState({
                    loading: false
                })

                this.alertShowForgetPassword('success', 'Yeni şifreniz e-posta adresinize gönderilmiştir')

            })
            .catch(error => {
                this.setState({loading: false})
                if (error.response === undefined) {
                    this.alertShowForgetPassword('error', 'Hatalı kullanıcı veya şifre')
                    return
                }
                if ((error.response.status !== 200) && (error.response.status !== 409)) {
                    this.alertShowForgetPassword('error', error.response.data.uimessage[0].text)
                } else {
                    this.alertShowForgetPassword('error', error.response.data.uimessage[0].text)
                }
            })


    }


    render() {

        if (this.state.activeForm === 'LOGIN') {
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <Container>
                        <Row className="justify-content-center">
                            <NotificationContainer/>
                            <ToastContainer/>
                            <LoadingPanel loadingShow={this.state.loading} loadingText={'Lütfen Bekleyin...'}/>
                            <Col md="8">
                                <div className='pull-center'>
                                    <img className="d-block"
                                         src={process.env.PUBLIC_URL + '/assets/img/brand/firma_logo.png'} alt="Kalem"
                                         style={{
                                             width: '150px',
                                             height: '150px'
                                         }}/>
                                </div>
                                <CardGroup>


                                    <Card className="p-4">
                                        <CardBody>
                                            <Form>

                                                <p className="text-muted">Hesabınızla Giriş Yapın</p>
                                                <InputGroup className='mb-3'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='icon-user'/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type='text' placeholder='Kullanıcı Adı' name='username'
                                                           value={this.state.userData.username}
                                                           onChange={e => this.handleInputChange(e.target.name, e)}/>
                                                </InputGroup>
                                                <InputGroup className='mb-4'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='icon-lock'/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type='password' placeholder='Şifre' name='password'
                                                           onChange={e => this.handleInputChange(e.target.name, e)}
                                                           onKeyPress={this.handleKeyPress}
                                                           value={this.state.userData.password}/>
                                                </InputGroup>
                                                <Row>
                                                    <Col xs="3">
                                                        <Button color="primary" className="px-4"
                                                                onClick={this.login}>Giriş</Button>
                                                    </Col>
                                                    <Col xs="9" className="text-right">
                                                        <NavLink href="#"
                                                                 onClick={this.openForgetPasswordForm}>Şifremi unuttum</NavLink>

                                                    </Col>
                                                </Row>
                                            </Form>
                                            <br/>
                                            <AlertMessages onClose={this.alertClose.bind(this)}
                                                           type={this.state.alertType}
                                                           show={this.state.alertShow}
                                                           message={this.state.alertMessage}/>
                                        </CardBody>
                                    </Card>
                                    <Card className="text-white bg-primary py-5 d-md-down-none" style={{width: '44%'}}>
                                        <CardBody className="text-center">
                                            <div>
                                                <h2>Online İşlem Merkezi</h2>
                                                <p>Online işlem merkezi ile öğrencinizin okulda, rahat ve sizin
                                                    gözetiminizde alışveriş yapabilmesi için gerekli olan bir çok işlemi
                                                    anında,
                                                    kolayca ve güvenli bir şekilde gerçekleştirebilirsiniz..</p>
                                                {/* <Link to="/register">
                                                <Button color="primary" className="mt-3" active tabIndex={-1}>Register Now!</Button>
                                            </Link>*/}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        else {
            return (

                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <NotificationContainer/>
                            <ToastContainer/>
                            <LoadingPanel loadingShow={this.state.loading} loadingText={'Lütfen Bekleyin...'}/>
                            <Col md="9" lg="7" xl="6">
                                <Card className="mx-4">
                                    <CardBody className="p-4">
                                        <Form>
                                            <h1>Şifre Sıfırla</h1>
                                            <p className="text-muted">Şifrenizi sıfırlamak için kullanıcı adınızı ve
                                                e-posta adresinizi giriniz.
                                                Yeni şifreniniz e-posta adresinize gönderilecektir.</p>
                                            <InputGroup className='mb-3'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='icon-user'/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type='text' placeholder='Kullanıcı Adı' name='username'
                                                       invalid={this.state.validForgetPasswordUserName}
                                                       value={this.state.forgetPasswordData.username}
                                                       onChange={e => this.handleForgetPasswordInputChange(e.target.name, e)}/>
                                                <FormFeedback>Bu alanın doldurulması zorunludur</FormFeedback>
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>@</InputGroupText>
                                                </InputGroupAddon>
                                                <Input type='text' placeholder='E-Posta' name='email_address'
                                                       onChange={e => this.handleForgetPasswordInputChange(e.target.name, e)}
                                                       invalid={this.state.validForgetPasswordEmailAddress}
                                                       value={this.state.forgetPasswordData.email_address}/>
                                                <FormFeedback>Bu alanın doldurulması zorunludur</FormFeedback>
                                            </InputGroup>

                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup row>
                                                        <AlertMessages onClose={this.alertCloseForgetPassword.bind(this)}
                                                                       type={this.state.alertType}
                                                                       show={this.state.alertShowForgetPassword}
                                                                       message={this.state.alertMessage}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                    <CardFooter className="p-4">
                                        <Row>
                                            <Col xs="12" sm="6">
                                                <Button color="success" onClick={this.passwordReset} block>Şifremi Sıfırla</Button>
                                            </Col>
                                            <Col xs="12" sm="6">
                                                <Button className='button-view-outline' block
                                                        onClick={this.closeForgetPasswordForm}>Vazgeç</Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }


    }
}

const mapPropsToState = (state, ownProps) => (
    {
        customStore: state
    })


Login = connect(mapPropsToState)(Login)
export default connect(mapPropsToState)(Login)
//export default Login
