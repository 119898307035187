import React from 'react'
import Loader from 'react-loader-spinner'

export default class LoadingPanel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    if (this.props.loadingShow) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1055
        }}>
          <Loader
            type='Oval'
            color='#00BFFF'
            height='50px'
            width='50px'
            background='transparent' />
          <label>{this.state.loadingShow}</label>

        </div>)
    } else {
      return (
        <div />
      )
    }
  }
}

// export default LoadingPanel
