import userData from './userReducer'
import sessionData from './sessionReducer'
import {combineReducers} from 'redux'


const reducersCombined= combineReducers({
  userData,
  sessionData


})

export default reducersCombined
