import axios from 'axios'
import authHeader from './auth-header'

//let API_URL = 'http://192.168.1.40:8097'

const onRequest = (methot, endpoint, data = {}, sessionData) => {

  let sessionData1={}
  if (sessionData !== undefined) {
    data= Object.assign({}, data, { session:sessionData });
    /*data.session.user_id = sessionData.user_id
    data.session.user_type = sessionData.user_type
    data.session.center_user = sessionData.center_user
    data.session.period_db_name = sessionData.period_db_name*/

  }


  return axios({
    url: localStorage.getItem('API_URL') + '/' + endpoint,
    method: methot,
    data: data,
    headers: authHeader(),
  }).then(res => res.data)
}


export default {onRequest}
