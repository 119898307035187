import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import '../scss/App.scss';
import {Provider} from 'react-redux'
import {Col, Container, Row} from "reactstrap";
// import { renderRoutes } from 'react-router-config';



const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));
// Pages


const Login = React.lazy(() => import('../modules/Login/components/Login'));
//const Login = React.lazy(() => import('../modules/StudentManagement/components/StudentForm'));

/*
const Login = Loadable({
  loader: () => import('../modules/Login/components/Login'),
  loading
});
*/

const DbConSettings = React.lazy(() => import('../modules/Management/components/DbConSettings'));

/*const DbConSettings = Loadable({
  loader: () => import('../modules/Management/components/DbConSettings'),
  loading
});*/


class App extends Component {
  constructor(props) {
    super(props)


    //APP_STARTUP_FORM :'LOGIN' -->Login sayfasını Aç
    ////APP_STARTUP_FORM :'DBSETTINGS' -->Sql server connection sayfasını Aç
    this.state = {
      APP_STARTUP_FORM: 'LOGIN'
    }

    this.getStartForm = this.getStartForm.bind(this)
  }


  componentWillMount() {
    this.getStartForm()
  }


  //config.json dosyası okunuyor


  getStartForm() {
    let startForm = ''


    fetch(process.env.PUBLIC_URL + '/config.json').then(response => {

      return response.json();
    }).then(data => {
      startForm = data[0].APP_STARTUP_FORM
      localStorage.setItem('API_URL', data[0].API_URL)
      if (startForm !== undefined) {
        this.setState({APP_STARTUP_FORM: data[0].APP_STARTUP_FORM})
      }
      else {
        this.setState({APP_STARTUP_FORM: 'LOGIN'})
      }

    }).catch(err => {
      this.setState({APP_STARTUP_FORM: 'LOGIN'})

    });
  }

  render() {

    if (this.state.APP_STARTUP_FORM === 'LOGIN') {
      return (
        <React.Suspense fallback={loading()}>
          <Provider store={this.props.store}>
            <HashRouter>
              <Switch>
                <Route exact path="/login" name="Login Page" component={Login} />
                <Route path="/" name="Home" component={DefaultLayout}/>
              </Switch>
            </HashRouter>
          </Provider>
        </React.Suspense>
      )
    }
    if (this.state.APP_STARTUP_FORM === 'DBCONSETTINGS') {
      return (
        <React.Suspense fallback={loading()}>
          <Provider store={this.props.store}>
            <HashRouter>
              <Switch>
                <Route name="Db Connection" component={DbConSettings}/>
              </Switch>
            </HashRouter>
          </Provider>
        </React.Suspense>
      )
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Config.json Dosya Hatası.</h4>
                <p className="text-muted float-left">Config.json dosyasındaki APP_STARTUP_FORM anahtarını kontrol
                  ediniz</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );


  }
}

export default App;
