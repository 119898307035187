import {store} from '../../index'

export function setSessionData(sessionData) {

  store.dispatch({
    type:'USER_LOGIN_SUCCESS',
    payload:{
      sessionData
    }
  })
}


