import {store} from '../../index'

export function setUserData(userData) {

  store.dispatch({
    type:'USER_LOGIN_SUCCESS',
    payload:{
      userData
    }
  })
}



/*export function setUserId(user_id) {
  store.dispatch({
    type:'SET_USER_ID',
    payload:{
      user_id
    }
  })
}*/
