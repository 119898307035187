import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.css';
import App from './components/App';
import {createStore} from 'redux';
import reducersCombined from './redux/reducers'
import * as serviceWorker from './serviceWorker';


const routes = require('./modules/index').default(store)


export const store=createStore(reducersCombined)


ReactDOM.render(<App store={store} routes={routes} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can view
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
