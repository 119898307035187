import React from 'react'

export default class AlertMessages extends React.Component {

  render () {
    if (!this.props.show) {
      return (
        <div />
      )
    }

    switch (this.props.type) {
      case 'success':
        return (
          <div className='alert alert-success alert-dismissible fade show' role='alert'>
            {this.props.message}
            <button type='button' className='close' data-dismiss='alert' aria-label='Close'
              onClick={this.props.onClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>)
        break

      case 'info':
        return (
          <div className='alert alert-info alert-dismissible fade show' role='alert'>
            {this.props.message}
            <button type='button' className='close' data-dismiss='alert' aria-label='Close'
              onClick={this.props.onClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>)
        break

      case 'warning':
        return (
          <div className='alert alert-warning alert-dismissible fade show' role='alert'>
            {this.props.message}
            <button type='button' className='close' data-dismiss='alert' aria-label='Close'
              onClick={this.props.onClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>)
        break

      case 'error':
        return (
          <div className='alert alert-danger alert-dismissible fade show' role='alert'>
            {this.props.message}
            <button type='button' className='close' data-dismiss='alert' aria-label='Close'
              onClick={this.props.onClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>)
        break
    }
  }
}
