export default function authHeader () {
  return {
    //'Authorization': 'Basic d9258f83-ae17-4e78-b146-76a2cbd07a09',
    'Content-Type': 'application/json'
}

  /* let user = JSON.parse(localStorage.getItem('user'))
  if (user && user.authdata) {
    return { 'Authorization': 'Basic 73fcf6b0-902e-404a-a34c-a6d8217d39f2' }
  } else {
    return {}
  } */
}
