

export default function sessionData(state={},action) {

  switch (action.type) {
    case'USER_LOGIN_SUCCESS':
      return Object.assign({},state,action.payload.sessionData)

    default:
      return state

  }

}
