// import react from 'react';
import BaseEntity from '../../../shared/models/BaseEntity'


class UserEntity extends BaseEntity {
    type = 0
    username = ''
    name = ''
    surname = ''
    password = ''
    new_password = ''
    new_password_repeat = ''
    fullname = ''
    email_address = ''
    role_type = ''
    city = ''
    town = ''
    phone = ''
    phone2 = ''

}

export default UserEntity
