

export default function userData(state={},action) {

  switch (action.type) {
    case'USER_LOGIN_SUCCESS':
      return Object.assign({},state,action.payload.userData)

    default:
      return state

  }

}
